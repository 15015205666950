
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "projectName": "forerunner",
  "projectVersion": "1.69.0",
  "isDev": false,
  "noScripts": false,
  "nuxt": {
    "buildId": "0e4ca603-bb9f-4fe2-a5d9-faf0f160172a"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
