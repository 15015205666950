<script setup lang="ts">
import ToastMessage from './ToastMessage.vue';
import { ToastPayload, toastEventBusyKey } from '#types/toast';
import { ref, useEventBus } from '#imports';

interface IProps {
  group?: string;
  container?: string;
  fullWidth?: boolean;
}

let messageIdx = 0;

const props = withDefaults(defineProps<IProps>(), { container: 'body', fullWidth: false });

const bus = useEventBus(toastEventBusyKey);

const toasts = ref<ToastPayload[]>([]);

function remove(id?: number) {
  let index = -1;

  for (let i = 0; i < toasts.value.length; i++) {
    if (toasts.value[i]?.id === id) {
      index = i;
      break;
    }
  }

  toasts.value.splice(index, 1);
}

bus.on((ev) => {
  if (ev.name === 'open' && props.group === ev.payload.group) {
    toasts.value.push({ ...ev.payload, id: messageIdx++ });
  }
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <Teleport :to="container">
    <div
      :class="[
        fullWidth ? 'left-20 right-20' : 'left-1/2 -translate-x-1/2 w-[410px]',
        container === 'body' ? 'fixed' : 'absolute',
        $attrs.class,
        'top-20',
      ]"
      :style="{ zIndex: 1000 }"
    >
      <TransitionGroup name="toast">
        <ToastMessage
          v-for="toast in toasts"
          :key="toast.id"
          v-bind="toast"
          class="w-full"
          @close="remove"
        />
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<style scoped>
.toast-move, /* apply transition to moving elements */
.toast-enter-active,
.toast-leave-active {
  @apply transition-all duration-100;
}

.toast-enter-from,
.toast-leave-to {
  @apply opacity-0 -translate-y-1/2;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.toast-leave-active {
  @apply absolute;
}
</style>
