import { useNuxtApp } from '#app';
import { Service } from '#types/service';
import { defineStore, ref, useAirpazCookie } from '#imports';

import { Currency } from '#types/config';

export const useConfigService = () => {
  const { $apifront } = useNuxtApp();

  const getFeatureFlags = (country: string) =>
    $apifront<Service<{ [key: string]: boolean }>>('/forerunner/features', { params: { country } });

  return {
    getFeatureFlags,
  };
};

export const useConfigStore = defineStore('config', () => {
  const ip = ref<string | null>(null);
  const country = ref<string | null>(null);
  const currency = ref<string | null>(null);

  const appstoreUrl = ref<string | null>(null);
  const playstoreUrl = ref<string | null>(null);
  const appgalleryUrl = ref<string | null>(null);

  const currencyConfig = ref<Currency | null>(null);
  const featureFlags = ref<{ [key: string]: boolean } | null>(null);

  async function syncFeatureFlags() {
    featureFlags.value = (await useConfigService().getFeatureFlags(country.value!)).result;
  }

  return {
    ip,
    country,
    currency,
    currencyConfig,
    featureFlags,
    appstoreUrl,
    playstoreUrl,
    appgalleryUrl,
    syncFeatureFlags,
  };
});

export const useSwitchCurrency = () => {
  const config = useConfigStore();
  const currencyCookie = useAirpazCookie('Currency');

  return function switchCurrency(currency: string | Currency) {
    if (typeof currency === 'string') {
      config.currency = currency;
      currencyCookie.value = currency;
    } else {
      config.currencyConfig = currency;
      config.currency = currency.currency;
      currencyCookie.value = currency.currency;
    }
  };
};
