import { pick, isEmpty, pickBy } from 'lodash-es';
import { Pinia } from 'pinia';
import { AdsTrafficParams, UtmTrafficParams } from '#types/tracker';
import {
  useRoute,
  useRequestHeaders,
  useConfigStore,
  useTrafficCookie,
  useTrafficSession,
  useAirpazCookie,
  defineNuxtPlugin,
  isStringEqual,
  isSet,
  getTrafficSourceByReferrer,
  safeBtoa,
} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const config = useConfigStore(nuxtApp.$pinia as Pinia);
  const headers = useRequestHeaders();

  const trafficCookie = useTrafficCookie();
  const trafficSession = useTrafficSession();
  const abandonedCartCookie = useAirpazCookie('abandoned_cart');

  const params = { ...route.query };

  if (/MicroMessenger|WeChat|Weixin/gi.test(headers['user-agent'] ?? '')) {
    params.aft = 'WECHAT';
  }

  if (!abandonedCartCookie.value && params.acart) {
    abandonedCartCookie.value = safeBtoa(JSON.stringify({ abandonedCart: params.acart }));
  }

  const aft = mapAffiliateTraffic(params);
  const ads = mapAdsTraffic(params, headers.referer || '');

  const utmParams = pickBy(params, (_, key) => key.startsWith('utm_'));

  if (!isEmpty(utmParams)) {
    trafficCookie.utm.value = utmParams as UtmTrafficParams;
  }

  if (!nuxtApp.ssrContext) {
    if (!isEmpty(utmParams)) {
      trafficSession.utm.value = utmParams as UtmTrafficParams;
    }

    if (aft || ads) {
      if (trafficCookie.ads.value) {
        trafficSession.ads.value = trafficCookie.ads.value;
      }

      if (trafficCookie.affiliate.value) {
        trafficSession.affiliate.value = trafficCookie.affiliate.value;
      }

      return;
    }
  }

  if (aft) {
    if (isStringEqual(aft.id, 'truemo')) {
      config.currency = 'THB';
    }

    trafficCookie.affiliate.value = aft;
  }

  if (trafficCookie.ads.value || isStringEqual((route.query.aft || '') as string, 'GFS')) {
    return;
  }

  if (ads) {
    // track landing page for organic traffic
    if (['GOOG', 'BING', 'NAVER', 'YHOOJP', 'YHOO', 'BAIDU'].includes(ads.a ?? '')) {
      useAirpazCookie('organic_traffic_landing').value = route.path;
    }

    trafficCookie.ads.value = ads;
  }
});

const defNull = (o: any) => (isSet(o) ? o : null);

function mapAdsTraffic(params: any, referrer: string) {
  const adsProps = ['s', 'a', 'b', 'm', 'p', 'k', 'c', 'd', 'dm', 't', 'n', 'df'];
  let ads: AdsTrafficParams = {
    s: null,
    a: null,
    b: null,
    m: null,
    p: null,
    k: null,
    c: null,
    d: null,
    dm: null,
    t: null,
    n: null,
    df: null,
  };

  if (isStringEqual(params.utm_source, 'bingads') || isSet(params.msclkid)) {
    ads = { ...ads, ...pick(params, adsProps) };
    ads.s = params.s || 'BINGADS';
  } else if (isStringEqual(params.utm_source, 'tiktokads')) {
    ads = { ...ads, ...pick(params, adsProps) };
    ads.s = 'TIKTOKADS';
  } else if (
    isStringEqual(params.source, 'YAHOO_JAPAN') ||
    isStringEqual(params.utm_source, 'yahoojpads') ||
    isStringEqual(params.utm_source, 'yjpads')
  ) {
    ads.s = params.s || 'YHOOADSJP';
    ads.a = defNull(params.a) || defNull(params.campaign);
    ads.k = defNull(params.keyword);
  } else if (
    ((isSet(params.gclid) || isSet(params.wbraid) || isSet(params.gbraid)) && isSet(params.a)) ||
    isStringEqual(params.utm_source, 'gads')
  ) {
    ads = { ...ads, ...pick(params, adsProps) };
    ads.s = params.s || 'GADS';
    ads.u = defNull(params.u);
    ads.l = defNull(params.l);
    if (isSet(params.u)) {
      const group = /(:)?aud-(.*?):/g.exec(params.u); // get the suffix of aud-
      if (isSet(group) && group?.[2]) ads.u = group[2];
    }
  } else if (
    (isSet(params.cid) && isSet(params.adsid) && isSet(params.aid)) ||
    isStringEqual(params.utm_source, 'fbads')
  ) {
    ads = { ...ads, ...pick(params, adsProps) };
    ads.s = 'FBADS';
  } else if (isStringEqual(params.utm_source, 'naverads')) {
    ads = { ...ads, ...pick(params, adsProps) };
    ads.s = 'NAVERADS';
  } else if (getTrafficSourceByReferrer(referrer)) {
    ads.a = getTrafficSourceByReferrer(referrer);
  }

  if (params.surl) {
    const refererSurl = getTrafficSourceByReferrer(params.referer || '');

    ads.a = ads.a ? ads.a : refererSurl || params.utm_source;
    ads.surl = params.surl;
  }

  if (Object.values(ads).every((val) => !val)) {
    return null;
  }

  return ads;
}

function mapAffiliateTraffic(params: any) {
  let clickId = '';
  const { aft, ...extras } = params;

  if (!aft) return null;

  if (!isEmpty(extras)) {
    clickId = Object.entries(extras).reduce((str, extra) => {
      const [key, value] = extra;
      if (key.includes('id')) str += `${value}`;

      return str;
    }, clickId);

    if (isStringEqual(aft, 'CLWIHK') && extras.extradata) {
      clickId = safeBtoa(`${clickId}#${extras.extradata}`);
    }

    if (isStringEqual(aft, 'INVASIA') && extras.session_id && extras.offer_id) {
      clickId = safeBtoa(`${extras.session_id}#${extras.offer_id}`);
    }
  }

  return {
    id: aft as string,
    clickId,
  };
}
